import * as React from "react"
import { useEffect, useState } from "react"
import "./about/photo/photo.scss"
import { graphql } from "gatsby"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import Title from "../components/Title"
import { PhotoCard } from "../components/cards/PhotoCard"
import HelpChoosing from "../components/offers/HelpChoosing"
import { AnimatePresence, motion } from "framer-motion"
import FormPopup from "../components/popups/FormPopup"
import { MENU_LINKS } from "../consts/MenuLinks"
import { STRAPI_API } from "../consts/api"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import Layout from "../components/Layout"
import {SEO} from "../components/seo";

const Photo = ({ data, location }) => {
  const { strapiPhotoPage, allStrapiPhotoReport } = data

  const allPhoto = allStrapiPhotoReport.nodes

  const [formPopup, setFormPopup] = useState(false)

  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(allPhoto.length > 6)

  const [list, setList] = useState([...allPhoto.slice(0, 6)])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore) {
      const currentLength = list.length
      const isMore = currentLength < allPhoto.length
      const nextResults = isMore
        ? allPhoto.slice(currentLength, currentLength + 6)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < allPhoto.length
    setHasMore(isMore)
  }, [list])

  return (
    <div>
      <SEO title={strapiPhotoPage?.titleDesc?.pageTitle} description={strapiPhotoPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="photoPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <Title
            value={[
              ["red", "Фото "],
              ["dark", "Pioneer DJ School"],
            ]}
          />

          {
            // PHOTO SCHOOL AND TEAM
            strapiPhotoPage?.schoolCover?.url &&
              strapiPhotoPage?.teamCover &&
              strapiPhotoPage?.schoolTitle &&
              strapiPhotoPage?.teamTitle && (
                <ul className="photoCards">
                  <motion.li whileHover={{ scale: 1.05 }}>
                    <PhotoCard
                      data={{
                        coverImg: STRAPI_API + strapiPhotoPage.schoolCover.url,
                        title: strapiPhotoPage.schoolTitle,
                        link: MENU_LINKS.photoDetailSchool,
                      }}
                    />
                  </motion.li>
                  <motion.li whileHover={{ scale: 1.05 }}>
                    <PhotoCard
                      data={{
                        coverImg: STRAPI_API + strapiPhotoPage.teamCover.url,
                        title: strapiPhotoPage.teamTitle,
                        link: MENU_LINKS.photoDetailTeam,
                      }}
                    />
                  </motion.li>
                </ul>
              )
          }

          <Title
            classAdd="photoPage__secondHeader m140"
            value={[
              ["dark", "Фотоотчеты "],
              ["red", "с мероприятий"],
            ]}
          />

          {
            // PHOTO REPORT
            list.length > 0 > 0 && (
              <div className="photoNews">
                <ul className="photoCards">
                  {list.map((item, i) => {
                    return (
                      <motion.li key={i} whileHover={{ scale: 1.05 }}>
                        <PhotoCard
                          data={{
                            coverImg: STRAPI_API + item?.galleryImg?.url,
                            title: item?.title,
                            link: `${MENU_LINKS.photo}${item.slug}/`,
                          }}
                        />
                      </motion.li>
                    )
                  })}
                </ul>

                {
                  // LOAD MORE DATA
                  hasMore && (
                    <div className=" moreButton">
                      <button className="moreButton" onClick={handleLoadMore}>
                        Показать еще 6 фотоотчетов
                      </button>
                    </div>
                  )
                }
              </div>
            )
          }

          {
            // OFFER WITH FORM - CHOOSING HELP
            strapiPhotoPage?.offer && (
              <div className="helpChoosing m140">
                <HelpChoosing data={strapiPhotoPage.offer} />
              </div>
            )
          }
        </DoubleBlock>
      </Layout>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiPhotoPage {
      schoolTitle
      schoolCover {
        url
      }
      schoolGallery {
        url
      }
      teamTitle
      teamCover {
        url
      }
      teamGallery {
        url
      }
      offer {
        title
        strapi_id
        button
      }
    }
    allStrapiPhotoReport {
      nodes {
        slug
        strapi_id
        title
        createDate
        galleryImg {
          url
        }
        titleDesc {
          pageTitle
          pageDescription
        }
      }
    }
  }
`

export default Photo
