import * as React from "react"
import Img from "react-cool-img"
import { Link } from "gatsby"

export function PhotoCard(props) {
  if (!props?.data) return <></>

  const { coverImg, title, link } = props.data

  return (
    <Link className="photoCard glassCard" to={link}>
      <div className="photoCard__coverBlock">
        <Img className="photoCard__cover" src={coverImg} alt="img" />
      </div>
      <div className="photoCard__buttons">
        <h4>{title}</h4>
        <span>Посмотреть</span>
      </div>
    </Link>
  )
}
